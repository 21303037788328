import { Button, Heading, Input, useToast } from "@chakra-ui/react"
import { isEqual } from "lodash-es"
import React, { FC } from "react"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { Json } from "../../components/Json"
import { NoSsr } from "../../components/NoSsrContext"
import {
  fromToastQuery,
  QueryToastOptions,
  withToastQuery,
} from "../../components/queryStringToast"

const toastOptions: QueryToastOptions = {
  status: "success",
  title: "Success",
  description: "Your new account password has been set.",
  isClosable: true,
}

const QueryStringToastInner: FC = () => {
  const toast = useToast()

  const encodedUrl = withToastQuery(
    "http://localhost:8000/debug/query-string-toast?one=true&two=false",
    toastOptions
  )

  const decoded = fromToastQuery(encodedUrl)

  return (
    <>
      <Heading fontSize="lg" as="h3">
        Original
      </Heading>
      <Json>{toastOptions}</Json>
      <Heading fontSize="lg" as="h3">
        Encoded
      </Heading>
      <Json>{encodedUrl}</Json>
      <Input value={new URL(encodedUrl).search} />
      <Heading fontSize="lg" as="h3">
        Decoded
      </Heading>
      <Json>{decoded}</Json>
      <Heading fontSize="lg" as="h3">
        Same?
      </Heading>
      <Json>{isEqual(toastOptions, decoded)}</Json>
      <Button onClick={() => toast(toastOptions)}>Trigger Toast</Button>
      <Button onClick={() => decoded && toast(decoded)}>
        Trigger Decoded Toast
      </Button>
    </>
  )
}

export default function QueryStringToast() {
  return (
    <DebugLayout title="Query String Toast">
      <NoSsr>
        <QueryStringToastInner />
      </NoSsr>
    </DebugLayout>
  )
}
